<script>
import { computed, getCurrentInstance } from 'vue'
import Permission from '@/permission'

import BaseLoading from '@/components/Reusable/BaseLoading.vue'
import { XIcon } from '@/components/ui-components/material-icons'
import { XCircularBackground } from '@/components/ui-components/circular-background'

export default {
  name: 'GroupTable',
  components: {
    XIcon,
    BaseLoading,
    XCircularBackground,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete'],
  setup() {
    const permissionRoleEdit = Permission.getUserPermission('roles.edit')
    const permissionRoleDelete = Permission.getUserPermission('roles.delete')

    const vm = getCurrentInstance().proxy
    const tableFields = computed(() => [
      {
        key: 'id',
        label: 'ID',
      },
      {
        key: 'name',
        label: vm.$t('name'),
        formatter: name => name[vm.$i18n.locale],
      },
      {
        key: 'actions',
      },
    ])

    function editItem(item) {
      vm.$router.push({
        name: 'role-v2-edit',
        params: {
          id: item.id,
        },
      })
    }

    return {
      permissionRoleEdit,
      permissionRoleDelete,

      tableFields,
      editItem,
    }
  },
}
</script>

<template>
  <b-table
    id="my-table"
    ref="apartment-list-table"
    thead-tr-class="row__head__bottom-border"
    tbody-tr-class="row__body__bottom-border"
    class="table__list"
    sticky-header
    borderless
    show-empty
    responsive
    sort-icon-left
    :items="items"
    :fields="tableFields"
    :busy="busy"
    :empty-text="$t('no_data')"
  >
    <template #table-busy>
      <base-loading />
    </template>

    <template #empty="scope">
      <span class="d-flex justify-content-center align-items-center">
        {{ scope["emptyText"] }}
      </span>
    </template>

    <template #cell(actions)="{ item }">
      <div class="float-right d-flex x-gap-1 cursor-pointer">
        <x-circular-background
          v-if="permissionRoleEdit"
          class="bg-violet-600"
          @click="editItem(item)"
        >
          <x-icon
            name="draw"
            class="color-white"
          />
        </x-circular-background>

        <x-circular-background
          v-if="permissionRoleDelete"
          class="bg-red-600"
          @click="$emit('delete',item.id)"
        >
          <x-icon
            name="delete"
            class="color-white"
          />
        </x-circular-background>
      </div>
    </template>
  </b-table>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/utils/b-table-redesign.scss";
</style>
